.old-ie {
  background: #00193b;
  color: white;
  text-align: center;
  padding-top: 30px;
  font-family: sans-serif;
  .fallback {
    display: inline !important;
    h3 {
      line-height: 14px;
    }
  }
}

::placeholder {
  opacity: 0.6;
}
